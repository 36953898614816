import { createRouter, createWebHashHistory } from 'vue-router'
import StartView from '../views/StartView.vue'

const routes = [
  // start
  {
    path: '/start',
    name: 'start',
    component: StartView
  },

  // player
  {
    path: '/p/not-started',
    name: 'PlayerNotStarted',
    meta: { player: true },
    component: () => import(/* webpackChunkName: "player" */ '../views/PlayerNotStartedView.vue')
  },
  {
    path: '/p/break',
    name: 'PlayerBreak',
    meta: { player: true },
    component: () => import(/* webpackChunkName: "player" */ '../views/PlayerBreakView.vue')
  },
  {
    path: '/p/task',
    name: 'PlayerTask',
    meta: { player: true, playerNav: true },
    component: () => import(/* webpackChunkName: "player" */ '../views/PlayerTaskView.vue')
  },
  {
    path: '/p/map',
    name: 'PlayerMap',
    meta: { player: true, playerNav: true },
    component: () => import(/* webpackChunkName: "player" */ '../views/PlayerMapView.vue')
  },

  // host
  {
    path: '/h/settings',
    name: 'HostSettings',
    meta: { hostNav: true },
    component: () => import(/* webpackChunkName: "host" */ '../views/HostSettingsView.vue')
  },
  {
    path: '/h/groups',
    name: 'HostGroups',
    meta: { hostNav: true },
    component: () => import(/* webpackChunkName: "host" */ '../views/HostGroupsView.vue')
  },
  {
    path: '/h/tasks',
    name: 'HostTasks',
    meta: { hostNav: true },
    component: () => import(/* webpackChunkName: "host" */ '../views/HostTasksView.vue')
  },
  {
    path: '/h/progress',
    name: 'HostProgress',
    meta: { hostNav: true },
    component: () => import(/* webpackChunkName: "host" */ '../views/HostProgressView.vue')
  },
  {
    path: '/h/map',
    name: 'HostMap',
    meta: { hostNav: true },
    component: () => import(/* webpackChunkName: "host" */ '../views/HostMapView.vue')
  },
  {
    path: '/special/inventory-codes',
    name: 'InventoryCodes',
    meta: { plain: true },
    component: () => import(/* webpackChunkName: "host" */ '../views/InventoryCodesForPrinting.vue')
  },
  // catch all other requests
  {
    path: '/:catchAll(.*)',
    redirect: '/start'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
