<template>
  <div class="start-view">
    <div class="heading px-3 py-4 bg-light">
        <h1 class="display-3">
          <img style="height: 60px;display:inline-block;margin-top: -10px;" src="/img/logo_light.png" alt="">
          Schnitzeljagd
        </h1>
    </div>
    <div class="join px-3 py-5 border-bottom border-top">
      <h2>Als Spieler beitreten</h2>
      <!--{{ usedSpace / 1000000000 }} / {{ totalSpace / 1000000000 }}-->
      <p>Um als Spieler beizutreten, scanne bitte den QR-Code deiner Gruppe. Diesen erhältst du vom Host des Spiels.</p>

      <div class="d-grid gap-2 d-block">
        <div class="input-group">
          <input v-model="joinCode" type="text" class="form-control" placeholder="enter or scan Code" />
          <button class="btn btn-secondary" type="button" @click="$refs.joinQrScanner.show()">
            <span class="mdi mdi-qrcode-scan"></span>
          </button>
        </div>
        <button v-if="!joinRunning" @click="join" class="btn btn-primary" type="button">Beitreten</button>
        <div v-else class="spinner-border text-primary" role="status"></div>
      </div>
      <QrScannerModal ref="joinQrScanner" @codeCaptured="code => joinCode = code" />

      <div v-if="joinError" class="alert alert-danger mt-2">
          Beitreten fehlgeschlagen
      </div>
    </div>
    <div class="create px-3 py-5">
      <h2>Spiel erstellen</h2>

      <div v-if="loggedIn" class="d-grid gap-2 d-block">
        <button @click="create" class="btn btn-primary" type="button">neues Spiel erstellen</button>

        <label class="mt-3">existierende Spiele:</label>
        <div v-if="loadingChases" class="spinner-border text-primary" role="status"></div>
        <div v-else-if="allChases.length > 0" class="list-group">
          <button v-for="chase in allChases" :key="chase.id" @click="open(chase.id)" type="button" class="list-group-item list-group-item-action">
            {{ chase.name }}
            <br>
            <span class="badge bg-primary">{{ chase.status }}</span>
            <br>
            <i class="text-secondary">{{ chase.created_time }}</i>
          </button>
        </div>
        <p v-else><i>bisher keine Spiele erstellt</i></p>
      </div>
      <div v-else class="d-grid gap-2 d-block">
        <label>Um ein Spiel zu hosten, musst du angemeldet sein.</label>
        <div class="input-group">
          <input v-model="loginCode" type="password" class="form-control" placeholder="Login credentials" />
          <button class="btn btn-secondary" type="button" @click="$refs.loginQrScanner.show()">
            <span class="mdi mdi-qrcode-scan"></span>
          </button>
        </div>
        <button v-if="!loginRunning" @click="login" class="btn btn-primary" type="button">Anmelden</button>
        <div v-else class="spinner-border text-primary" role="status"></div>

        <QrScannerModal ref="loginQrScanner" @codeCaptured="code => loginCode = code" />
        <div v-if="loginError" class="alert alert-danger mt-2">
          Anmeldung fehlgeschlagen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import QrScannerModal from '../components/QrScannerModal.vue'

export default {
  name: 'StartView',
  components: {
    QrScannerModal
  },
  data () {
    return {
      joinCode: '',
      joinRunning: false,
      joinError: false,
      loginCode: '',
      loginError: false,
      loginRunning: false,
      loadingChases: false
    }
  },
  methods: {
    ...mapActions(['loginUser', 'loginPlayer', 'createNewChase', 'loadAllChases', 'loadChase']),
    async login () {
      this.loginError = false
      this.loginRunning = true
      try {
        this.loginError = !(await this.loginUser(this.loginCode))
        if (!this.loginError) this.loginCode = ''
      } catch (err) {
        console.error(err)
        this.loginError = true
      }

      this.loginRunning = false
    },
    async create () {
      if (await this.createNewChase()) this.$router.push('/h/settings')
    },
    async open (chaseId) {
      if (await this.loadChase({ chaseId, asHost: true })) this.$router.push('/h/settings')
    },
    async join () {
      this.joinError = false
      this.joinRunning = true
      const success = await this.loginPlayer(this.joinCode)
      if (success) {
        await this.loadChase({ asHost: false })
        this.$router.push('/p/task')
      }
      this.joinError = success
      this.joinRunning = false
    }
  },
  computed: {
    ...mapState(['loggedIn', 'allChases'])
  },
  watch: {
    loggedIn (val) {
      if (val === true && !this.loadingChases) {
        this.loadingChases = true
        this.loadAllChases().then(() => {
          this.loadingChases = false
        })
      }
    }
  },
  mounted () {
    if (this.loggedIn) {
      this.loadingChases = true
      this.loadAllChases().then(() => {
        this.loadingChases = false
      })
    }
  }
}
</script>
