<template>
<div class="main">
  <div v-if="$route.meta.hostNav" class="head p-2 bg-light border-bottom d-flex flex-row">
      <img style="height: 50px" src="/img/logo_light.png" alt="">
      <div class="flex-grow-1">
        <span class="badge bg-secondary float-end">host</span>
        <h1>{{ currentChase.name }}</h1>
      </div>
  </div>
  <div v-if="$route.meta.playerNav" class="head p-2 bg-light border-bottom d-flex flex-row">
      <img style="height: 50px" src="/img/logo_light.png" alt="">
      <div class="flex-grow-1">
        <span class="badge bg-primary float-end">player</span>
        <b>{{ currentChase.name }}</b>
        <br>
        <b class="text-primary">{{ currentChase.group.name}}</b>
      </div>
  </div>
  <router-view class="router-view" />

  <div v-if="$route.meta.playerNav" class="mobile-nav border-top p-0 bg-light">
    <router-link to="/p/task" :class="[$route.path === '/p/task' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-checkbox-multiple-marked-outline"></span>
    </router-link>
    <router-link to="/p/map" :class="[$route.path === '/p/map' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-map"></span>
    </router-link>
    <!--
    <router-link to="/p/chat" :class="[$route.path === '/p/chat' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-forum"></span>
    </router-link>
    -->
  </div>

  <div v-if="$route.meta.hostNav" class="mobile-nav border-top p-0 bg-light">
    <router-link to="/h/settings" :class="[$route.path === '/h/settings' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-cog"></span>
    </router-link>
    <router-link to="/h/groups" :class="[$route.path === '/h/groups' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-account-group"></span>
    </router-link>
    <router-link to="/h/tasks" :class="[$route.path === '/h/tasks' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-transit-connection-variant"></span>
    </router-link>
    <router-link to="/h/progress" :class="[$route.path === '/h/progress' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-progress-wrench"></span>
    </router-link>
    <router-link to="/h/map" :class="[$route.path === '/h/map' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-map"></span>
    </router-link>
    <!--
    <router-link to="/h/chat" :class="[$route.path === '/h/chat' ? 'text-primary mobile-nav-active' : 'text-muted']">
      <span class="mdi mdi-forum"></span>
    </router-link>
    -->
  </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  methods: {
    ...mapActions(['checkLogin', 'restoreCurrentChase', 'uploadCachedFulfillments']),
    async uploadSmallFiles () {
      if (!this.isHost) {
        if (Date.now() - this.lastUploadMedia > 6000) {
          await this.uploadCachedFulfillments()
        }
      }
      setTimeout(this.uploadSmallFiles, 3000)
    }
  },
  created () {
    this.$router.beforeEach(async (to, from) => {
      if (to.meta.hostNav) {
        const isHost = await this.restoreCurrentChase()
        if (isHost === true) return true
        else return '/'
      } else if (to.meta.player) {
        const isHost = await this.restoreCurrentChase()
        if (isHost === false) {
          const groupStarted = !(this.currentChase.group.group_start_time === null || new Date(this.currentChase.group.group_start_time).getTime() > Date.now())
          if ((this.currentChase.status === 'not-started' || !groupStarted) && to.meta.playerNav) {
            return '/p/not-started'
          } else if (this.currentChase.status === 'break' && to.meta.playerNav) {
            return '/p/break'
          } else if (this.currentChase.status !== 'not-started' && groupStarted && to.path === '/p/not-started') {
            return '/p/task'
          } else if (this.currentChase.status !== 'break' && to.path === '/p/break') {
            return '/p/task'
          } else return true
        } else return '/'
      }
      return true
    })
  },
  computed: {
    ...mapState(['isHost', 'currentChase', 'lastUploadMedia'])
  },
  mounted () {
    this.checkLogin()
    this.uploadSmallFiles()
  },
  watch: {
    'currentChase.status' (status) {
      if (!this.isHost) {
        const groupStarted = !(this.currentChase.group.group_start_time === null || new Date(this.currentChase.group.group_start_time).getTime() > Date.now())
        if ((status === 'not-started' || !groupStarted) && this.$route.path !== '/p/not-started') {
          this.$router.push('/p/not-started')
        } else if (status !== 'not-started' && groupStarted && this.$route.path === '/p/not-started') {
          this.$router.push('/p/task')
        } else if (status === 'break' && this.$route.path !== '/p/break') {
          this.$router.push('/p/break')
        } else if (status !== 'break' && this.$route.path === '/p/break') {
          this.$router.push('/p/task')
        }
      }
    },
    'currentChase.group.group_start_time' (val) {
      if (!this.isHost) {
        const groupStarted = !(val === null || new Date(val).getTime() > Date.now())
        const status = this.currentChase.status

        if ((status === 'not-started' || !groupStarted) && this.$route.path !== '/p/not-started') {
          this.$router.push('/p/not-started')
        } else if (status !== 'not-started' && groupStarted && this.$route.path === '/p/not-started') {
          this.$router.push('/p/task')
        }
      }
    }
  }
}
</script>

<style>
html, body, #app {
  height: 100%;
}

.main {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.router-view {
  flex-grow: 1;
  overflow: auto;
}

.mobile-nav {
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.mobile-nav > * {
  font-size: 28px;
}

.mobile-nav-active {
}
</style>
