<template>
  <div
    class="modal fade"
    ref="scannerModal"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <span :key="refreshCounter" class="mdi mdi-circle text-secondary circle-fade"></span>
            Scan code
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
            <div v-if="!isLoaded">
          <span>Loading scanner...</span>
          <div class="spinner-border" role="status"></div>
          <div class="alert alert-warning" role="alert">
            Please allow camera access!
          </div>
            </div>
          <div v-if="error !== null" class="alert alert-danger" role="alert">
            <b>Error:</b> {{ error }}
          </div>

          <div v-if="isShown">
            <v-select v-model="selectedDeviceId" :options="videoDevices" label="label" :reduce="opt => opt.deviceId"></v-select>
            <video style="width:100%;height:300px" poster="data:image/gif,AAAA" ref="scanner"></video>
          </div>
          <!--
          <StreamBarcodeReader
            v-if="isShown"
            @decode="onDecode"
            @loaded="onLoaded"
            @init="onInit"
            :torch="torchOn && torchSupported"
          ></StreamBarcodeReader>
          -->
          <button class="btn btn-secondary" @click="toggleTorch"><span class="mdi mdi-flashlight"></span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import StreamBarcodeReader from './StreamBarcodeReader'
// import { StreamBarcodeReader } from 'vue-barcode-reader'
import { BrowserMultiFormatReader, BrowserCodeReader } from '@zxing/browser'
import { Modal } from 'bootstrap'

export default {
  name: 'QrScannerModal',
  components: {
    // StreamBarcodeReader
  },
  props: {
  },
  data () {
    return {
      codeReader: null,
      videoDevices: [],
      captureControls: null,
      selectedDeviceId: '',
      isShown: false,
      isLoaded: false,
      scannerModal: null,
      error: null,
      torchSupported: false,
      torchOn: false,
      refreshCounter: 0
    }
  },
  methods: {
    show () {
      this.isShown = true
      this.scannerModal.show()
      this.init()
    },
    onDecode (text) {
      this.captureControls.stop()
      this.$emit('codeCaptured', text.text)
      this.scannerModal.hide()
    },

    onLoaded () {
      this.isLoaded = true
    },
    async onInit (promise) {
      // show loading indicator
      console.log('init called')
      try {
        const { capabilities } = await promise

        this.torchSupported = !!capabilities.torch
        this.error = null

        console.log('no errors')

        // successfully initialized
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
          this.error = 'Camera access was denied by user.'
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
          this.error = 'No suitable camera device was found.'
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
          this.error = 'Camera access is not supported.'
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
          this.error = 'Camera cannot be captured. Maybe it is already in use.'
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
          this.error = 'camera cannot be captured.'
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
          this.error = 'Browser does not support Camera stream capturing.'
        }
      } finally {
        // hide loading indicator
      }
    },
    async init () {
      this.isLoaded = false
      this.codeReader = new BrowserMultiFormatReader()
      this.videoDevices = [{ label: 'default', deviceId: undefined }, ...await BrowserCodeReader.listVideoInputDevices()]
      this.selectedDeviceId = undefined
      await this.start()
    },
    async start () {
      const selectedDevice = this.selectedDeviceId

      this.captureControls = await this.codeReader.decodeFromVideoDevice(selectedDevice, this.$refs.scanner, (result, error, controls) => {
        this.refreshCounter++
        if (error) {
          // console.error(error)
          return
        }

        controls.stop()
        this.onDecode(result)
      })
      this.isLoaded = true
    },
    toggleTorch () {
      this.torchOn = !this.torchOn
      this.captureControls.switchTorch(this.torchOn)
    }
  },
  watch: {
    isShown (val) {
      if (val === false) {
        this.isLoaded = false
        if (this.torchOn) this.toggleTorch()
        this.captureControls.stop()
      }
    },
    selectedDeviceId () {
      this.start()
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef
    this.scannerModal = new Modal(this.$refs.scannerModal)
    this.$refs.scannerModal.addEventListener('hidden.bs.modal', event => {
      this.isShown = false
    })
  }
}
</script>

<style scoped>
@keyframes circle_fade_animation {
  from {
    opacity: 1;
  }
  to  {
    opacity: 0;
  }
}

.circle-fade {
  animation: circle_fade_animation 0.5s linear;
  animation-fill-mode: both;
}

</style>
