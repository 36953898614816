import { createStore } from 'vuex'
import { io } from 'socket.io-client'
import router from '../router'
import { distVincenty } from '@/lib/geoDistance'
import sleep from '@/lib/Sleep'
import { promiseRequest } from '@/lib/PromiseIndexedDB'

const localStorageUserTokenKey = 'thechase_usertoken'
const localStoragePlayerTokenKey = 'thechase_playertoken'
const localStorageCurrentChaseKey = 'thechase_currchase'
const localStorageCurrentChaseIsHostKey = 'thechase_currchaseIsHost'

const indexedDBName = 'thechase2'
const request = indexedDB.open(indexedDBName)
let iDB
request.onupgradeneeded = function () {
  // The database did not previously exist, so create object stores and indexes.
  const db = request.result
  db.createObjectStore('upload_cache', { keyPath: 'id' })
  db.createObjectStore('upload_cache_data', { keyPath: 'id' })
}

request.onsuccess = function () {
  iDB = request.result
}

const largeFiles = 1024 * 1024 * 20 // 20 MiB

let socket = null

/*
async function digestMessage (message) {
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('') // convert bytes to hex string
  return hashHex
}
*/

export default createStore({
  state: {
    userToken: localStorage.getItem(localStorageUserTokenKey),
    playerToken: localStorage.getItem(localStoragePlayerTokenKey),
    loggedIn: false,
    user: null,
    currentChase: null,
    isHost: null,
    allChases: [],
    allTasks: {},
    latestLocation: null,
    continuousLocation: false,
    continousLocationWatchId: null,
    groupLocations: {},
    lastUploadMedia: 0,
    lastUploadLargeMedia: 0
  },
  getters: {
  },
  mutations: {
    SET_USER_TOKEN (state, payload) {
      state.userToken = payload
    },
    SET_PLAYER_TOKEN (state, payload) {
      state.playerToken = payload
    },
    SET_LOGGED_IN (state, payload) {
      state.loggedIn = payload
    },
    SET_USER (state, payload) {
      state.user = payload
    },
    SET_CURRENT_CHASE (state, payload) {
      state.currentChase = payload
    },
    SET_IS_HOST (state, payload) {
      state.isHost = payload
    },
    SET_ALL_CHASES (state, payload) {
      state.allChases = [...payload]
    },
    CHANGE_CHASE (state, { key, value }) {
      state.currentChase[key] = value
    },
    CHANGE_GROUP (state, group) {
      state.currentChase.groups = [...state.currentChase.groups.filter(a => a.id !== group.id), group].sort((a, b) => a.id - b.id)
    },
    CHANGE_PLAYER_GROUP (state, group) {
      state.currentChase.group = group
    },
    REMOVE_GROUP (state, groupId) {
      state.currentChase.groups = state.currentChase.groups.filter(a => a.id !== groupId)
    },
    SET_TASKS_PLAYER (state, payload) {
      state.currentChase.tasks = payload
    },
    SET_TASKS_HOST (state, { groupId, tasks }) {
      state.allTasks[groupId] = tasks
    },
    CHANGE_TASK (state, task) {
      state.currentChase.tasksNodes = [...state.currentChase.tasksNodes.filter(a => a.id !== task.id), task].sort((a, b) => a.id - b.id)
    },
    REMOVE_TASK (state, taskId) {
      state.currentChase.tasksNodes = state.currentChase.tasksNodes.filter(a => a.id !== taskId)
    },
    CHANGE_TASK_EDGE (state, edge) {
      state.currentChase.tasksEdges = [...state.currentChase.tasksEdges.filter(a => a.from !== edge.from || a.to !== edge.to), edge]
    },
    REMOVE_TASK_EDGE (state, edge) {
      state.currentChase.tasksEdges = state.currentChase.tasksEdges.filter(a => a.from !== edge.from || a.to !== edge.to)
    },
    SET_CONTINUOUS_LOCATION (state, payload) {
      state.continuousLocation = payload
    },
    SET_LATEST_LOCATION (state, payload) {
      state.latestLocation = payload
    },
    SET_CONTINUOUS_LOCATION_WATCH_ID (state, payload) {
      state.continousLocationWatchId = payload
    },
    SET_GROUP_LOCATION (state, { groupId, location }) {
      state.groupLocations = { ...state.groupLocations, [groupId]: { location, time: Date.now() } }
    },
    CHANGE_FULFILLMENT (state, fulfillment) {
      state.currentChase.fulfillments = [...state.currentChase.fulfillments.filter(a => a.task !== fulfillment.task || a.group !== fulfillment.group), fulfillment]
    },
    SET_LAST_UPDATE_MEDIA (state, { value, isLarge }) {
      if (isLarge) {
        state.lastUploadLargeMedia = value
      }
      state.lastUploadMedia = value
    }
  },
  actions: {
    async checkLogin ({ commit, state }, force = false) {
      if (state.user === null || force) {
        const res = await fetch('/api/login', {
          headers: {
            token: state.userToken
          }
        })

        const jsonRes = await res.json()
        commit('SET_LOGGED_IN', jsonRes.loggedIn)
        if (jsonRes.loggedIn) commit('SET_USER', jsonRes.user)
      }
    },
    async loginUser ({ commit, dispatch }, secretUserCode) {
      try {
        const res = await fetch('/api/login', {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            codeUser: secretUserCode
          })
        })

        const jsonRes = await res.json()
        if (jsonRes.ok) {
          commit('SET_USER_TOKEN', jsonRes.token)
          localStorage.setItem(localStorageUserTokenKey, jsonRes.token)
        }
        dispatch('checkLogin', true)

        return jsonRes.ok
      } catch (err) {
        console.error(err)
        return false
      }
    },
    async loginPlayer ({ commit, dispatch }, secretGroupCode) {
      try {
        const res = await fetch('/api/login', {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            codePlayer: secretGroupCode
          })
        })

        const jsonRes = await res.json()
        if (jsonRes.ok) {
          commit('SET_PLAYER_TOKEN', jsonRes.token)
          localStorage.setItem(localStoragePlayerTokenKey, jsonRes.token)
        }

        return jsonRes.ok
      } catch (err) {
        console.error(err)
        return false
      }
    },
    async createNewChase ({ commit, dispatch, state }) {
      const res = await fetch('/api/chase?create_new=true', {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          token: state.userToken,
          'Content-Type': 'application/json'
        }
      })

      const jsonRes = await res.json()
      if (jsonRes.ok) {
        await dispatch('loadChase', { chaseId: jsonRes.chaseId, asHost: true })
      }

      return !!jsonRes.ok
    },
    async loadChase ({ commit, dispatch, state }, { chaseId, asHost }) {
      const res = await fetch('/api/chase?ashost=' + (asHost ? ('yes&get=' + chaseId) : 'no'), {
        cache: 'no-cache',
        headers: {
          token: asHost ? state.userToken : state.playerToken
        }
      })

      const jsonRes = await res.json()
      if (jsonRes.ok) {
        commit('SET_CURRENT_CHASE', jsonRes.chase)
        commit('SET_IS_HOST', asHost)
        dispatch('openChaseSocket')
        localStorage.setItem(localStorageCurrentChaseKey, jsonRes.chase.id)
        localStorage.setItem(localStorageCurrentChaseIsHostKey, asHost)
        /*
        if (!asHost) {
          commit('SET_TASKS_PLAYER', jsonRes.tasks)
        }
        */
      } else {
        commit('SET_CURRENT_CHASE', null)
        commit('SET_IS_HOST', null)
      }

      return !!jsonRes.ok
    },
    async loadAllChases ({ commit, state }) {
      const res = await fetch('/api/chase?ashost=yes&all=true', {
        headers: {
          token: state.userToken
        }
      })

      const jsonRes = await res.json()
      if (jsonRes.ok) {
        commit('SET_ALL_CHASES', jsonRes.chases)
      } else {
        commit('SET_ALL_CHASES', [])
      }
    },
    async restoreCurrentChase ({ dispatch, state }) {
      if (state.currentChase === null) {
        const restoredId = localStorage.getItem(localStorageCurrentChaseKey)
        if (localStorage.getItem(localStorageCurrentChaseIsHostKey) === 'true') {
          await dispatch('checkLogin')
          await dispatch('loadChase', { chaseId: restoredId, asHost: true })
        } else {
          await dispatch('loadChase', { chaseId: restoredId, asHost: false })
        }
      }

      return state.isHost
    },
    async openChaseSocket ({ dispatch, commit, state }) {
      if (state.currentChase === null) return
      if (state.isHost === null) return

      if (socket !== null) {
        socket.disconnect()
        socket.close()
      }

      const auth = { chaseId: state.currentChase.id }
      if (state.isHost) {
        auth.userToken = state.userToken
      } else {
        auth.playerToken = state.playerToken
      }

      socket = io(undefined, {
        auth,
        transports: ['polling']
      })

      let sendLocationInterval = null
      let lastLocation = [0, 0]
      let lastLocationSent = 0

      socket.on('disconnect', (reason) => {
        if (reason === 'io server disconnect') {
          console.error('connection ended by server')
          socket = null
          dispatch('stopContinuousLocation')
          if (sendLocationInterval !== null) {
            clearInterval(sendLocationInterval)
            sendLocationInterval = null
          }
          router.push('/')
        }
      })

      // activate position logging if player
      console.log(state.isHost)
      if (!state.isHost) {
        dispatch('startContinuousLocation', loc => {
          // check if any task position is reached
          let didFulfill = false
          state.currentChase.tasks
            .filter(a => a.type === 'gps')
            .filter(a => {
              const distance = distVincenty(a.content.location[0], a.content.location[1], loc[0], loc[1]) // in meters
              const proximityThreshold = a.content.proximityThreshold || 20 // in meters 20 as default
              return distance <= proximityThreshold
            })
            .forEach(a => {
              socket.emit('fulfillTask', { taskId: a.id })
              didFulfill = true
            })

          if (didFulfill || (distVincenty(lastLocation[0], lastLocation[1], loc[0], loc[1]) > 10) || Date.now() - lastLocationSent > 60000) {
            socket.emit('location', loc)
            lastLocation = loc
            lastLocationSent = Date.now()
          }
        })
        // socket.emit('location', state.latestLocation)
        /*
        sendLocationInterval = setInterval(() => {
          if (socket) {
            socket.emit('location', state.latestLocation)
            lastLocation = state.latestLocation
          }
        }, 20000)
        */
      }

      socket.on('chaseChanged', ({ key, value }) => {
        commit('CHANGE_CHASE', { key, value })
      })

      socket.on('groupChanged', group => {
        console.log(group)
        if (state.isHost) {
          commit('CHANGE_GROUP', group)
        } else {
          commit('CHANGE_PLAYER_GROUP', group)
        }
      })

      socket.on('groupRemoved', groupId => {
        commit('REMOVE_GROUP', groupId)
      })

      socket.on('tasksHost', ({ tasks, groupId }) => {
        commit('SET_TASKS_HOST', { tasks, groupId })
      })

      socket.on('tasksPlayer', tasks => {
        commit('SET_TASKS_PLAYER', tasks)
      })

      socket.on('taskChanged', task => {
        commit('CHANGE_TASK', task)
      })

      socket.on('taskEdgeChanged', edge => {
        commit('CHANGE_TASK_EDGE', edge)
      })

      socket.on('taskEdgeRemoved', edge => {
        commit('REMOVE_TASK_EDGE', edge)
      })

      socket.on('taskRemoved', taskId => {
        commit('REMOVE_TASK', taskId)
      })

      socket.on('groupLocation', ({ groupId, location }) => {
        commit('SET_GROUP_LOCATION', { groupId, location })
      })

      socket.on('fulfillmentChanged', (fulfillment) => {
        commit('CHANGE_FULFILLMENT', fulfillment)
      })
      /*
      socket.on('fulfillmentsChanged', (fulfillments) => {
        fulfillments.forEach(fulfillment => commit('CHANGE_FULFILLMENT', fulfillment))
      })
        */

      socket.on('fulfillmentFileContentRequest', async ({ taskId, fileIndex, position }) => {
        console.log('upload req', { taskId, fileIndex, position })
        const tx = iDB.transaction(['upload_cache', 'upload_cache_data'], 'readwrite')
        const store = tx.objectStore('upload_cache')
        const storeData = tx.objectStore('upload_cache_data')

        // const chunkSize = 1024 * 10 // 10 KiB
        const chunkSize = 1024 * 1024 * 1 // 1 MiB

        const entryId = `${taskId}--${fileIndex}`
        const request = await promiseRequest(store.get(entryId))

        commit('SET_LAST_UPDATE_MEDIA', {
          value: Date.now(),
          isLarge: request.result.size > largeFiles
        })

        if (request.result.size - position <= 0) {
          await promiseRequest(store.delete(entryId))
          await promiseRequest(storeData.delete(entryId))
        } else {
          const requestData = await promiseRequest(storeData.get(entryId))
          const data = requestData.result.content.slice(position, position + chunkSize)
          console.log(data.length)
          await socket.timeout(300000).emitWithAck('uploadFulfillmentContent', {
            taskId,
            fileIndex,
            position,
            data: requestData.result.content.slice(position, position + chunkSize)
          })
        }
      })
    },
    changeChase ({ state }, { key, value }) {
      if (socket !== null) {
        socket.emit('changeChase', { key, value })
      }
    },
    addGroup ({ state }) {
      if (socket !== null) {
        socket.emit('addGroup')
      }
    },
    removeGroup ({ state }, groupId) {
      if (socket !== null) {
        socket.emit('removeGroup', groupId)
      }
    },
    changeGroup ({ state }, { groupId, key, value }) {
      if (socket !== null) {
        socket.emit('changeGroup', { groupId, key, value })
      }
    },
    changeTask ({ state }, { taskId, key, value }) {
      if (socket !== null) {
        socket.emit('changeTask', { taskId, key, value })
      }
    },
    addTask ({ state }) {
      if (socket !== null) {
        socket.emit('addTask')
      }
    },
    removeTask ({ state }, taskId) {
      if (socket !== null) {
        socket.emit('removeTask', taskId)
      }
    },
    addTaskEdge ({ state }, { from, to, slot }) {
      if (socket !== null) {
        socket.emit('addTaskEdge', { from, to, slot })
      }
    },
    removeTaskEdge ({ state }, { from, to }) {
      if (socket !== null) {
        socket.emit('removeTaskEdge', { from, to })
      }
    },
    async storeFulfillmentFiles ({ state }, fileList) {
      const tx = iDB.transaction(['upload_cache', 'upload_cache_data'], 'readwrite')
      const store = tx.objectStore('upload_cache')
      const storeData = tx.objectStore('upload_cache_data')

      await Promise.all(fileList.map(async ({ content, ...file }) => {
        await store.put({ ...file, id: `${file.taskId}--${file.fileIndex}` })
        await storeData.put({ content, id: `${file.taskId}--${file.fileIndex}` })
      }))
    },
    async fulfillTask ({ state }, data) {
      if (socket !== null) {
        socket.emit('fulfillTask', data)
      }
    },
    async updateFulfillment ({ state }, { groupId = undefined, taskId, modifierFunction }) {
      // modifies an already existing fulfillment and repeat until transaction is successful
      if (groupId === undefined) groupId = state.currentChase.group.id
      const previousFulfillment = state.currentChase.fulfillments.find(f => f.group === groupId && f.task === taskId)
      if (previousFulfillment === undefined) {
        console.error('Cannot find fulfillment to update')
        return
      }

      while (true) {
        const newData = modifierFunction(JSON.parse(JSON.stringify(previousFulfillment.data)))
        const status = await socket.timeout(10000).emitWithAck('updateFulfillment', { groupId, taskId, payload: newData, version: previousFulfillment.version })

        console.log(status)
        if (status === 'OK') {
          break
        } else {
          await sleep(2000)
        }
      }
    },
    async uploadCachedFulfillments ({ state, commit }, uploadLarge = false) {
      if (!iDB) return

      commit('SET_LAST_UPDATE_MEDIA', {
        value: Date.now(),
        isLarge: uploadLarge
      })

      const tx = iDB.transaction('upload_cache', 'readonly')
      const store = tx.objectStore('upload_cache')

      const request = store.openCursor()

      request.onsuccess = async () => {
        const cursor = request.result
        if (cursor) {
          if ((cursor.value.size < largeFiles) || uploadLarge) {
            // notify server that this file is still cached and available for being requested
            socket.emit('availableFulfillmentFile', {
              taskId: cursor.value.taskId,
              fileIndex: cursor.value.fileIndex
            })
          }
          cursor.continue()
        }
      }
    },
    getInfoUploadCache ({ state }) {
      return new Promise((resolve, reject) => {
        const tx = iDB.transaction('upload_cache', 'readonly')
        const store = tx.objectStore('upload_cache')

        const result = {
          numSmall: 0,
          numLarge: 0,
          sizeSmall: 0,
          sizeLarge: 0
        }

        const request = store.openCursor()
        request.onsuccess = async () => {
          const cursor = request.result
          if (cursor) {
            if (cursor.value.size < largeFiles) {
              result.numSmall++
              result.sizeSmall += cursor.value.size
            } else {
              result.numLarge++
              result.sizeLarge += cursor.value.size
            }

            cursor.continue()
          } else {
            resolve(result)
          }
        }
      })
    },
    getLocation ({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.continuousLocation) {
          console.log('continues location returned')
          resolve(state.latestLocation)
        } else {
          // fix a single position
          console.log('retrieve fresh location')
          navigator.geolocation.getCurrentPosition(pos => {
            const loc = [pos.coords.latitude, pos.coords.longitude]
            commit('SET_LATEST_LOCATION', loc)
            resolve(loc)
          }, error => {
            console.log(error)
            reject(error)
          }, {
            enableHighAccuracy: true,
            maximumAge: 5000
          })
        }
      })
    },
    startContinuousLocation ({ state, commit }, callback) {
      if (!state.continuousLocation) {
        commit('SET_CONTINUOUS_LOCATION', true)
        const watchId = navigator.geolocation.watchPosition(pos => {
          console.log(pos)
          const loc = [pos.coords.latitude, pos.coords.longitude]
          commit('SET_LATEST_LOCATION', loc)
          if (callback) callback(loc)
        }, error => {
          console.error(error)
        }, {
          enableHighAccuracy: true,
          maximumAge: 5000
        })
        commit('SET_CONTINUOUS_LOCATION_WATCH_ID', watchId)
      }
    },
    stopContinuousLocation ({ state, commit }) {
      if (state.continuousLocation) {
        commit('SET_CONTINOUS_LOCATION', false)
        navigator.geolocation.clearWatch(this.continousLocationWatchId)
      }
    }
  },
  modules: {
  }
})
