export function promiseRequest (request) {
  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      reject(event)
    }
    request.onsuccess = (event) => {
      resolve(request)
    }
  })
}
